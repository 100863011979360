.circle {
	border-radius: 50%;
	background-color: transparent;
	border: 2px solid #565dab;
	width: 50px;
	height: 50px;
	position: absolute;
	opacity: 0;
	animation: scaleIn 4s infinite cubic-bezier(0.36, 0.11, 0.89, 0.32);
}

@keyframes scaleIn {
	from {
		transform: scale(0.5, 0.5);
		opacity: 0.5;
	}
	to {
		transform: scale(2.5, 2.5);
		opacity: 0;
	}
}

.banner-image-container img {
	width: 50%;
}

@media (min-width: 768px) {
	.banner-image-container img {
		width: 100%;
	}
}

.banner-image-container {
	align-self: flex-end;
	animation-duration: 2s;
	animation-iteration-count: infinite;
	margin: 0 auto 0 auto;
	transform-origin: bottom;
	width: 200px;
	animation-name: bounce-2;
	animation-timing-function: ease;
}

@keyframes bounce-2 {
	0% {
		transform: translateY(0);
	}
	50% {
		transform: translateY(-50px);
	}
	100% {
		transform: translateY(0);
	}
}
body {
	overflow-x: auto;
}

.banner-animation {
	animation: updown 3s linear 0s infinite;
}

@keyframes updown {
	0% {
		transform: translateY(0);
	}
	50% {
		transform: translateY(-25px);
	}
	100% {
		transform: translateY(0);
	}
}
